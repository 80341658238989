import React, { useEffect, useState } from "react"
import { Link, useIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Hooks
import useTranslation from "../hooks/useTranslation"

// Utils
import getCookieValue from "../utils/getCookieValue"

// Styles
import "../styles/pages/price-calculator.scss"

// Components
import Chart from "../components/chart"

const FormArea = () => {
  const [hubspotToken, setHubspotToken] = useState("")
  useEffect(() => {
    setHubspotToken(getCookieValue("hubspotutk"))
  }, [])
  const { t } = useTranslation()
  const intl = useIntl()
  const [regions, setRegions] = useState({
    EU: {
      title: t("Europe"),
      percentage: 73,
    },
    US: {
      title: t("United States"),
      percentage: 2,
    },
    AS: {
      title: t("Asia"),
      percentage: 10,
    },
    RoW: {
      title: t("Rest of the World"),
      percentage: 15,
    },
  })
  const [volume, setVolume] = useState(50000)
  const [currencies, setCurrencies] = useState(["EUR"])
  const types = [
    t("Online retail goods"),
    t("Online services"),
    t("Digital goods"),
    t("Online food ordering"),
    t("Travel (hotels, flights, car rental)"),
    // t("Gambling & gaming"),
    t("Crypto & forex"),
    t("Adult digital or dating"),
    t("Pharma or nutra"),
    t("Licensed money transfer services"),
  ]
  const countries = [
    t("Austria"),
    t("Belgium"),
    t("Bulgaria"),
    t("Croatia"),
    t("Cyprus"),
    t("Czech Republic"),
    t("Denmark"),
    t("Estonia"),
    t("Finland"),
    t("France"),
    t("Germany"),
    t("Greece"),
    t("Hungary"),
    t("Ireland"),
    t("Italy"),
    t("Latvia"),
    t("Lithuania"),
    t("Luxembourg"),
    t("Malta"),
    t("Netherlands"),
    t("Poland"),
    t("Portugal"),
    t("Romania"),
    t("Slovakia"),
    t("Slovenia"),
    t("Spain"),
    t("Sweden"),
    t("United Kingdom"),
    t("Iceland"),
    t("Norway"),
    t("Liechtenstein"),
  ]
  const currenciesName = [
    "EUR",
    "GBP",
    "USD",
    "RON",
    "NOK",
    "SEK",
    "DKK",
  ]
  function percentageChange(event, region) {
    if (event.target.value > 100) return
    let newRegions = { ...regions }
    newRegions[region].percentage = Number(event.target.value)
    setRegions(newRegions)
  }
  function currenciesChange(event, currency) {
    let newCurrencies = [...currencies]
    if (event.target.checked) {
      newCurrencies.push(currency)
    } else {
      newCurrencies = newCurrencies.filter(item => item !== currency)
    }
    setCurrencies(newCurrencies)
  }
  return (
    <div className="columns">
      <div className="column is-three-fifths">
        <div className="is-card">
          <form
            name="Price Form"
            method="POST"
            data-netlify="true"
            action="/form-success"
          >
            <input
              aria-label="form-name"
              type="hidden"
              name="form-name"
              value="Price Form"
            />
            <input
              aria-label="language"
              type="hidden"
              name="language"
              value={intl.locale}
            />
            <input
              type="hidden"
              name="Hubspot User Toker"
              aria-label="Hubspot User Toker"
              value={hubspotToken}
            />
            <h2 className="title is-4 is-spaced">
              {t("Your Business Information")}
            </h2>
            <div className="columns">
              <div className="column is-one-third">
                <div className="field">
                  <label className="label is-size-7" htmlFor="website">
                    {t("What’s your website URL?")}
                    <span className="has-text-danger">*</span>
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      aria-label="website"
                      name="Website"
                      type="text"
                      id="website"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="field">
                  <label className="label is-size-7" htmlFor="type">
                    {t("What’s your business type?")}
                    <span className="has-text-danger">*</span>
                  </label>
                  <div className="control">
                    <div className="select">
                      <select
                        defaultValue=""
                        name="Business Type"
                        id="type"
                        required
                      >
                        <option value="" disabled>
                          {t("Please Select")}
                        </option>
                        {types.map(type => (
                          <option key={type}>{type}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="field">
                  <label className="label is-size-7" htmlFor="country">
                    {t("Country of Incorporation")}{" "}
                    <span className="has-text-danger">*</span>
                  </label>
                  <div className="control">
                    <div className="select">
                      <select
                        defaultValue=""
                        name="Country"
                        id="country"
                        required
                      >
                        <option value="" disabled>
                          {t("Please Select")}
                        </option>
                        {countries.map(country => (
                          <option key={country}>{country}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column is-two-thirds">
                <div className="field">
                  <label className="label is-size-7" htmlFor="volume">
                    {t("What’s your expected monthly online sales volume?")}{" "}
                    <span className="has-text-danger">*</span>
                  </label>
                  <div className="control slider">
                    <input
                      type="range"
                      min="0"
                      max="100000"
                      value={volume}
                      onChange={e => setVolume(e.target.value)}
                      aria-label="volume"
                      id="volume"
                      name="Monthly Sales Volume"
                    />
                    <div
                      style={{ width: `${(volume / 100000) * 100}%` }}
                      className="slider-filled has-background-primary"
                    ></div>
                  </div>
                </div>
              </div>

              <div className="column is-one-third">
                <div className="field">
                  <div className="control has-margin-top-4 has-icons-right">
                    <input
                      className="input"
                      type="number"
                      min="0"
                      max="100000"
                      aria-label="volume"
                      value={volume}
                      onChange={e =>
                        e.target.value > 100000
                          ? ""
                          : setVolume(Number(e.target.value))
                      }
                    />
                    <span className="icon is-small is-right">EUR</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="has-margin-bottom-4">
              <label className="label is-size-7" htmlFor="currencies">
                {t(
                  "In what currencies will your customers be able to pay on your website?"
                )}
                <span className="has-text-danger">*</span>
              </label>
              {currenciesName.map(currency => (
                <label key={currency} className="checkbox has-margin-right-5">
                  <input
                    type="checkbox"
                    className="has-margin-right-3"
                    defaultChecked={currency === "EUR"}
                    onChange={e => currenciesChange(e, currency)}
                    aria-label="currencies"
                  />
                  {currency}
                </label>
              ))}
              <input
                type="hidden"
                name="Currencies"
                value={currencies.join("; ")}
                aria-label="currencies"
              />
            </div>
            <div className="has-margin-bottom-5">
              <label
                className="label is-size-7 has-margin-bottom-4"
                htmlFor="percentage"
              >
                {t(
                  "Please specify the percentage of transactions you estimate you’ll have, split by region"
                )}
                <span className="has-text-danger">*</span>
              </label>
              <div className="columns">
                {Object.keys(regions).map(region => (
                  <div key={region} className="column is-one-quarter ">
                    <div className="field">
                      <label
                        key={region}
                        className="label is-size-7"
                        htmlFor={regions[region].title}
                      >
                        {regions[region].title}
                      </label>
                      <div className="control has-icons-left">
                        <input
                          className="input"
                          value={regions[region].percentage}
                          onChange={e => percentageChange(e, region)}
                          type="number"
                          name={region}
                          id={region}
                          aria-label="percentage"
                          required
                        />
                        <span className="icon is-left">%</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <h2 className="title is-4 is-spaced">
              {t("Your Contact Details")}
            </h2>
            <div className="columns">
              <div className="column is-half">
                <div className="field">
                  <label className="label is-size-7" htmlFor="firstname">
                    {t("First name")}
                    <span className="has-text-danger">*</span>
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      name="Firstname"
                      type="text"
                      id="firstname"
                      aria-label="firstname"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="column is-half">
                <div className="field">
                  <label className="label is-size-7" htmlFor="lastname">
                    {t("Last name")}
                    <span className="has-text-danger">*</span>
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      name="Lastname"
                      type="text"
                      id="lastname"
                      aria-label="lastname"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-half">
                <div className="field">
                  <label className="label is-size-7" htmlFor="email">
                    {t("Email address")}
                    <span className="has-text-danger">*</span>
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      name="Email"
                      type="email"
                      id="email"
                      aria-label="email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="column is-half">
                <div className="field">
                  <label className="label is-size-7" htmlFor="mobile">
                    {t("Mobile number")}
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      name="Mobile"
                      aria-label="mobile"
                      id="mobile"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="has-margin-bottom-5">
              <p className="is-size-7">
                {t(
                  "Twispay is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:"
                )}
              </p>
              <label className="checkbox has-margin-right-5 is-size-7">
                <input
                  type="checkbox"
                  className="has-margin-right-3"
                  name="Agree to receive emails"
                  aria-label="Agree to receive emails "
                />
                {t("I agree to receive useful information from Twispay.")}
              </label>
            </div>
            <div className="has-margin-bottom-5">
              <p className="is-size-7">
                {t(
                  "In order to provide you the content requested, we need to store and process your personal data. If you consent to us storing your personal data for this purpose, please tick the checkbox below."
                )}
              </p>
              <label className="checkbox has-margin-right-5 is-size-7">
                <input
                  type="checkbox"
                  className="has-margin-right-3"
                  name="Allow Twispay to store"
                  aria-label="Allow Twispay to store"
                  required
                />
                {t(
                  "I agree to allow Twispay to store and process my personal data."
                )}
                <span className="has-text-danger">*</span>
              </label>
            </div>
            <p className="is-size-7 has-margin-bottom-5">
              {t(
                "You may unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy."
              )}
              <Link className="has-text-primary" to="/terms-and-conditions">
                {t("Please review our Privacy Policy")}
              </Link>
            </p>
            <button className="button is-rounded is-success is-medium">
              {t("Submit")}
            </button>
          </form>
        </div>
      </div>
      <div className="column">
        <h2 className="title is-4 is-spaced">
          {t("Your Revenue Breakdown Structure")}
        </h2>
        <p className="subtitle is-6 has-text-grey-light">
          {t(
            "Based on your inputs, here is a revenue projection per market that we'll account for when presenting you with your premium rate"
          )}
        </p>
        <Chart bars={regions} activeBar="all">
          {Object.keys(regions).map(region => (
            <span
              className="has-text-secondary has-text-weight-bold "
              key={region}
              style={{ width: `${100 / Object.keys(regions).length}%` }}
            >
              {region}
            </span>
          ))}
        </Chart>
      </div>
    </div>
  )
}
const PriceCalculatorPage = () => {
  const { t } = useTranslation()
  return (
    <Layout
      stripeClass="is-transparent"
      pageName="price-calculator"
      hasLightBackground
      isWider
    >
      <SEO title={t("Twispay Price Calculator")} />
      <section className="section is-hero">
        <div className="container">
          <h1 className="has-text-centered title is-2">
            {t("Get Your Free Quote")}
          </h1>
          <h2
            style={{ maxWidth: 700, margin: "auto" }}
            className="subtitle is-4 has-margin-bottom-7 has-text-centered "
          >
            {t(
              "Want to accurately predict what the processing costs will be for your business?Give us a few bits of information about it and we'll calculate it for you"
            )}
          </h2>
          <FormArea />
        </div>
      </section>
    </Layout>
  )
}

export default PriceCalculatorPage
