import React from "react"
import PropTypes from "prop-types"

// Styles
import "../styles/components/chart.scss"

const chart = ({ children, bars, activeBar, setActiveBar }) => {
  return (
    <>
      <div className="chart-wrapper has-margin-bottom-4">
        {Object.keys(bars).map(bar => {
          if (setActiveBar) {
            return (
              <button
                style={{
                  height: bars[bar].height
                    ? bars[bar].height
                    : bars[bar].percentage + "%",
                }}
                key={bar}
                className={`bar is-size-6 ${
                  bar === activeBar || activeBar === "all" ? "active" : ""
                }`}
                onClick={() => setActiveBar(bar)}
              >
                {bars[bar].height ? (
                  <p className="has-margin-top-4 has-text-weight-bold">
                    {bars[bar].percentage}%
                  </p>
                ) : (
                  <></>
                )}
              </button>
            )
          } else {
            return (
              <div
                style={{
                  height: bars[bar].height
                    ? bars[bar].height
                    : bars[bar].percentage + "%",
                }}
                key={bar}
                className={`bar is-size-6 ${
                  bar === activeBar || activeBar === "all" ? "active" : ""
                }`}
              >
                {bars[bar].height ? (
                  <p className="has-margin-top-4 has-text-weight-bold">
                    {bars[bar].percentage}%
                  </p>
                ) : (
                  <></>
                )}
              </div>
            )
          }
        })}
      </div>
      <div className="chart-labels">{children}</div>
    </>
  )
}
chart.propTypes = {
  children: PropTypes.node.isRequired,
  bars: PropTypes.object.isRequired,
  activeBar: PropTypes.string.isRequired,
  setActiveBar: PropTypes.func,
}
export default chart
